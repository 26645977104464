import React, { useState, useEffect } from "react";

import moment from "moment";

import styles from "./ClaimSuggestionCard.module.scss";

export default (props) => {
  const { claim } = props;

  return (
    <div className={styles.conversation}>
      <div className={styles.metadata}>
        <div className={styles.labelContainer}>Spelling / Grammar</div>
      </div>
      <div className={styles.claimCaption}>{claim.caption}</div>
      <div className={styles.bottomContainer}>
        <div className={styles.nameDate}>
          <div className={styles.name}></div>
        </div>
      </div>
    </div>
  );
};
