import React, { useState, useEffect } from "react";

import moment from "moment";

import styles from "./SingleReferenceDocumentCard.module.scss";

export default (props) => {
  const { claim } = props;

  return (
    <div className={styles.conversation}>
      <div className={styles.metadata}>
        <div className={styles.labelContainer}>Reference</div>
      </div>
      <div className={styles.claimCaption}>{claim.caption}</div>
      <div className={styles.bottomContainer}>
        <div className={styles.nameDate}>
          <div className={styles.name}>{claim.created_by_user_name}</div>
          <div className={styles.dotSeparator}>&#9679;</div>
          <div className={styles.date}>
            <div className={styles.fullDateTimeContainer}>
              <div className={styles.fullDateTime}>{claim.created_at}</div>
              <div className={styles.bottomArrow} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
