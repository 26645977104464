import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import CloseSVG from "../../../../images/icons/svg/close.svg";
import styles from "./PaigeSuggestionPanel.module.scss";
import loadingAnimation from "../../../../images/lotties/loader.json";
import httpClient from "../../../../lib/HttpClient";
import NewModal from "../../../Common/Modal";
import notificationService from "../../../../services/Notifications";
//import fetchDocumentReferences from "../../../../actions/document/fetchDocumentReferences";
import linkIcon from "../../../../images/icons/svg/link-icon.svg";
import security from "../../../../services/Security";
import { Link } from "react-router-dom";
import SingleReferenceDocumentCard from "./SingleReferenceDocumentCard.js";
import ClaimSuggestionCard from "./ClaimSuggestionCard.js";
import SpellingGrammarSuggestionCard from "./SpellingGrammarSuggestionCard.js";
import moreIcon from "../../../../images/icons/svg/more-white.svg";
import PaigeClaimsAnimation from "../../../../images/lotties/paige-claims-animation.json";

import { Line } from "rc-progress";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikFormInputGroup,
  UikInput,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
} from "@uik";

export default (props) => {
  const {
    docId,
    closeDocumentReferencePanel,
    versionId,
    versionNumber,
    originalDocumentId,
    isApproved,
    readOnly,
    onAnnotationSelect,
  } = props;

  const [totalPageCount, setTotalPageCount] = useState(0);
  const [searchResults, setSearchResults] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [documentReferencesByPage, setDocumentReferencesByPage] = useState({});
  const [pageRangeError, setPageRangeError] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState(0);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const paigeSuggestions = useSelector((state) => {
    return state.paigeSuggestions;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let paigeSuggestionsObj = {};
  }, [paigeSuggestions]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultPaigeOptions = {
    loop: true,
    autoplay: true,
    animationData: PaigeClaimsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const loadingStages = [
    {
      text1: "Hold tight!",
      text2: "Paige is reviewing for relevant product claims",
    },
    {
      text1: "Almost there!",
      text2: "Paige is almost done finding references",
    },
    {
      text1: "Only a few more seconds",
      text2: "Paige is reviewing for spelling and grammar",
    },
  ];

  const close = () => {
    closeDocumentReferencePanel();
  };

  const hidePaigeSuggestions = () => {
    console.log("hide paige annotations");
    const instance = window.WebViewer.getInstance();
    const annotationManager =
      instance.Core.documentViewer.getAnnotationManager();
    const annots = annotationManager.getAnnotationsList();
    let paige = [];
    annots.map((annot) => {
      if (annot.getCustomData("highlight-type") === "paige") {
        paige.push(annot);
      }
    });
    paige.map((a) => {
      annotationManager.hideAnnotation(a);
    });
  };

  const showReference1 = () => {
    hidePaigeSuggestions();
    const instance = window.WebViewer.getInstance();
    const annotationManager =
      instance.Core.documentViewer.getAnnotationManager();
    let xfdf_string = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><pdf-info xmlns="http://www.pdftron.com/pdfinfo" version="2" import-version="4" /><fields /><annots><highlight page="0" rect="72,617.800,467.450,648.875" color="#4E7DE9" flags="print" name="4cb948df-ee3a-d6d8-00cf-148560c9aa11" title="Antonio Salumbides" subject="Highlight" date="D:20240916233026-04'00'" interior-color="#4E7DE9" opacity="0.5" creationdate="D:20240916233026-04'00'" coords="72,648.875,467.44999999999993,648.875,72,634.875,467.44999999999993,634.875,72,631.8,282.57499999999993,631.8,72,617.8,282.57499999999993,617.8"><trn-custom-data bytes="{&quot;highlight-type&quot;:&quot;paige&quot;}"/><contents>In attention-deficit/hyperactivity disorder (ADHD) that has not shown improvement with standard therapy.</contents></highlight></annots><pages><defmtx matrix="1,0,0,-1,0,792" /></pages></xfdf>`;
    annotationManager.importAnnotations(xfdf_string);
  };
  const showClaim1 = () => {
    hidePaigeSuggestions();
    const instance = window.WebViewer.getInstance();
    const annotationManager =
      instance.Core.documentViewer.getAnnotationManager();
    let xfdf_string = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><pdf-info xmlns="http://www.pdftron.com/pdfinfo" version="2" import-version="4" /><fields /><annots><highlight page="0" rect="72,566.575,285.650,580.575" color="#4E7DE9" flags="print" name="883a5857-ce08-736b-0296-56a73e01df03" title="Antonio Salumbides" subject="Highlight" date="D:20240916233040-04'00'" interior-color="#4E7DE9" opacity="0.5" creationdate="D:20240916233040-04'00'" coords="72,580.575,285.65,580.575,72,566.575,285.65,566.575"><trn-custom-data bytes="{&quot;highlight-type&quot;:&quot;paige&quot;}"/><contents>Never lose focus with Aflurix therapy.</contents></highlight></annots><pages><defmtx matrix="1,0,0,-1,0,792" /></pages></xfdf>`;
    annotationManager.importAnnotations(xfdf_string);
  };
  const showReference2 = () => {
    hidePaigeSuggestions();
    const instance = window.WebViewer.getInstance();
    const annotationManager =
      instance.Core.documentViewer.getAnnotationManager();
    let xfdf_string = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><pdf-info xmlns="http://www.pdftron.com/pdfinfo" version="2" import-version="4" /><fields /><annots><highlight page="0" rect="72,498.275,518.500,529.350" color="#4E7DE9" flags="print" name="01f15fb0-c374-41c7-cba2-5a82759f1dd8" title="Antonio Salumbides" subject="Highlight" date="D:20240916233049-04'00'" interior-color="#4E7DE9" opacity="0.5" creationdate="D:20240916233049-04'00'" coords="72,529.35,518.5,529.35,72,515.35,518.5,515.35,72,512.275,331.05,512.275,72,498.275,331.05,498.275"><trn-custom-data bytes="{&quot;highlight-type&quot;:&quot;paige&quot;}"/><contents>AFLURIX + standard care demonstrated a median symptom-free period of 22.7 hours vs 11.9 hours with standard care alone.</contents></highlight></annots><pages><defmtx matrix="1,0,0,-1,0,792" /></pages></xfdf>`;
    annotationManager.importAnnotations(xfdf_string);
  };
  const showSpellingGrammar = () => {
    hidePaigeSuggestions();
    const instance = window.WebViewer.getInstance();
    const annotationManager =
      instance.Core.documentViewer.getAnnotationManager();
    let xfdf_string = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><pdf-info xmlns="http://www.pdftron.com/pdfinfo" version="2" import-version="4" /><fields /><annots><highlight page="0" rect="72,361.675,488.400,392.750" color="#4E7DE9" flags="print" name="092f3b2a-84cd-73e8-2220-57546ea48a0c" title="Antonio Salumbides" subject="Highlight" date="D:20240916233106-04'00'" interior-color="#4E7DE9" opacity="0.5" creationdate="D:20240916233106-04'00'" coords="72,392.75,488.3999999999999,392.75,72,378.75,488.3999999999999,378.75,72,375.675,464.69999999999993,375.675,72,361.675,464.69999999999993,361.675"><trn-custom-data bytes="{&quot;highlight-type&quot;:&quot;paige&quot;}"/><contents>Treatment with AFLURIX may increase focus in patients who do not show sufficient improvement on standard therapy (partial or no response).</contents></highlight></annots><pages><defmtx matrix="1,0,0,-1,0,792" /></pages></xfdf>`;
    annotationManager.importAnnotations(xfdf_string);
  };

  useEffect(() => {
    //Fake Loading
    setTimeout(() => {
      setLoadingPercentage((prevPercentage) => {
        if (prevPercentage === 60) {
          setLoadingStage(1);
        } else if (prevPercentage === 100) {
          setLoading(false);
        }
        if (prevPercentage !== 100) {
          return prevPercentage + 20;
        }
      });
    }, 500);
  }, [loadingPercentage]);

  const renderEmtpyState = () => {
    const referenceDocument1 = {
      label: "aflurix-comprehensive.pdf",
      caption:
        "Paige Intelligence found a reference from the Aflurix Comprehensive document (aflurix-comprehensive.pdf)",
      created_by_user_name: "aflurix-comprehensivei.pdf",
      created_at: "IND.MDD",
    };
    const claimSuggestion = {
      label: "aflurix-comprehensive.pdf",
      caption:
        'The statement "Never lose focus with Aflurix therapy" is not an approved statement. Instead use "Lengthen your focus with Aflurix therapy" which was found on page 6 of the approved document "Aflurix-Drug-Detail.pdf".',
      created_by_user_name: "Aflurix-Drug-Detail.pdf",
      created_at: "Page 6",
    };
    const referenceDocument2 = {
      label: "aflurix-comprehensive.pdf",
      caption:
        "Paige Intelligence found a reference from the Aflurix Comprehensive document (aflurix-comprehensive.pdf)",
      created_by_user_name: "aflurix-comprehensive.pdf",
      created_at: "FB.H1",
    };
    const spellingGrammarSuggestion = {
      label: "aflurix-comprehensive.pdf",
      caption:
        "Treatment with Aflurix may increase focus in patients who do not show sufficient improvement on standard therapy (partial or no response).",
      created_by_user_name: "",
      created_at: "",
    };
    return (
      <div>
        {loading ? (
          <div className={styles.loadingState}>
            <div className={styles.aiRobot}>
              <Lottie options={defaultPaigeOptions} height={92} width={82} />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.text1}>
                {loadingStages[loadingStage].text1}
              </div>
              <div className={styles.text2}>
                {loadingStages[loadingStage].text2}
              </div>
              <div className={styles.text3}>
                {loadingStages[loadingStage].text3}
              </div>
            </div>
            <Line
              className={styles.progressBar}
              percent={loadingPercentage}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="round"
            />
          </div>
        ) : (
          <div className={styles.conversationsContainer}>
            <div key={1234} className={styles.page}>
              <div className={styles.pageTitleContainer}>
                <div className={styles.title}>Suggestions</div>
              </div>
              <div className={styles.dividerContainer} />
              <div className={styles.cardContainer}>
                <div>
                  <span onClick={showReference1}>
                    <SingleReferenceDocumentCard claim={referenceDocument1} />
                  </span>
                  <span onClick={showClaim1}>
                    <ClaimSuggestionCard claim={claimSuggestion} />
                  </span>
                  <span onClick={showReference2}>
                    <SingleReferenceDocumentCard claim={referenceDocument2} />
                  </span>
                  <span onClick={showSpellingGrammar}>
                    <SpellingGrammarSuggestionCard
                      claim={spellingGrammarSuggestion}
                    />
                  </span>
                </div>
                <div className={styles.dividerContainer} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const actionsDropDown = ({ onClick }) => {
    return (
      <span onClick={onClick}>
        <img className={styles.moreIcon} src={moreIcon} />
      </span>
    );
  };

  const renderPaigeSuggestionList = () => {
    return <div className={styles.conversationsContainer}></div>;
  };

  return (
    <div className={styles.conversationsPanel}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.title}>Paige</div>
          </div>
          <div className={styles.rightContainer}>
            <div onClick={close} className={styles.closeButton}>
              <img className={styles.closeIcon} src={CloseSVG} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>{renderEmtpyState()}</div>
      {showLoader && (
        <div className={styles.loaderContainer}>
          <Lottie options={defaultOptions} height={92} width={82} />
          <p className={styles.loaderText}>Looking For Comments ...</p>
        </div>
      )}
    </div>
  );
};
